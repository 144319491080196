@use "sass:math";

.lni-c-elevator__result {
    color: $text--alternate;
    font-family: $font-family--franklin;

    @include type-setting(lg);
}

// smaller that 600px
.lni-c-elevator__inputs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;


    @include min-screen-width(400px) {
        flex-direction: row;
        justify-content: flex-end;
    }
}

.lni-c-elevator__amount-input {
    max-width: 19rem;
    width: 100%;
}

.lni-c-elevator__submit {
    margin-top: math.div($space, 2);
    margin-left: 0;

    @include min-screen-width(400px) {
        margin-left: math.div($space, 2);
    }

    @include min-screen-width(400px) {
        margin-top: 0;
    }
}
