@use "sass:math";

.lni-c-featured {
    color: $featured__text;
    font-family: $font-family--franklin;

    @include type-setting(xl);
}

.lni-c-featured__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    background-color: $featured__border; //the gap is the "border"

    .lni-c-featured__content {
        border-bottom: none;
    }
}

.lni-c-featured__block {
    flex: 1 1 17ch;
    background-color: $background--site;
}

.lni-c-featured__block:hover {
    background-color: $featured__background--hover;
}

.lni-c-featured__content {
    border-bottom: solid 1px $featured__border;
}

.lni-c-featured__content.--form {
    border-bottom: 1px solid rgb(93 95 112 / 12%);
    width: 78%;
}

.lni-c-featured-form__link {
    @include type-setting(sm);

    font-family: $font-family--franklin;
}

.lni-c-featured__link {
    flex: 1 1 auto;
    width: 100%;
    text-decoration: none;

    @include min-screen-width(420px) {
        width: 50%;
        max-width: 50%;
    }

    @include min-screen-width(768px) {
        width: 33%;
    }

    &.--news-events {
        max-width: 100%;
    }

    &:hover {
        background-color: $featured__background--hover;
    }
    &::before {
        margin-left: math.div($space, 2);
        margin-right: math.div($space, 2);
        text-align: center;
        vertical-align: top;

        @include type-setting(sm);
    }
    &.--form:hover {
        background-color: transparent;
        color: $featured-icon__color;

        @include box-shadow(2);
    }
    &.--blank {
        &:hover {
            background-color: transparent;
        }
    }
}

.lni-c-featured__title {
    color: $featured-title__text;
    font-family: $font-family--franklin;

    @include type-setting(xs);

    line-height: 24px;
    &.--form {
        color: $featured-form-title__text;
    }
}

.lni-c-featured__description {
    color: $featured-description__text;

    @include type-setting(xxs);

    line-height: 20px;
}

.lni-c-featured__info {
    @include type-setting(xxs);

    text-transform: uppercase;
    letter-spacing: 1.5px;
}