/**
*
* Generate consistent font sizes and line heights
* @require $space
*
*/

/*
 * NOTE: There is a line break issue for all "@" rules due to a bug in stylelint.
 * https://github.com/stylelint/stylelint/issues/3274
 * So we disable it here:
 */
/* stylelint-disable indentation */

/* Keep size of fonts consistent */
$type-scale: (
    'xxs': 0.75rem,
    'xs': 0.875rem,
    'sm': 1rem,
    'md': 1.125rem,
    'lg': 1.25rem,
    'xl': 1.75rem,
    'xxl': 2.25rem
);

/* Vertical rhythm */
$line-heights: (
    'xxs': $space,
    'xs': $space,
    'sm': $space,
    'md': $space,
    'lg': $space,
    'xl': $space * 1.25,
    'xxl': $space * 1.5
);

@function type-scale($level) {
    @return map-get($type-scale, $level);
};

@function line-height($level) {
    @return map-get($line-heights, $level);
};

@mixin type-setting($level: sm) {
    font-size: type-scale($level);
    line-height: line-height($level);
};
