@use "sass:math";
/* stylelint-disable no-duplicate-selectors */
//this will be addressed in http://lnidaptumtfs:8080/tfs/AppDev/LNI/_workitems/edit/241299

.lni-c-hero-headline {
    color: $text--inverse;
}

.lni-c-hero-headline-title {
    @include type-setting('xl');

    font-family: $font-family--franklin;
    font-weight: 700;
    line-height: 2;
}

.lni-c-hero-headline-description {
    @include type-setting(xs);

    line-height: 1.7;
    padding: math.div($space, 2) 0;
}

.lni-c-hero-headline-divider {
    border: 0 solid $border--white;
    border-top-width: 3px;
    width: $space * 3;
    display: inline-block;
}

@media (width >= 900px) {
    .lni-c-hero-headline {
        display: flex;
    }

    .lni-c-hero-headline-title {
        -ms-grid-row-align: center;
        align-self: center;
        flex-shrink: 0;
    }

    .lni-c-hero-headline-description {
        -ms-grid-row-align: center;
        align-self: center;
    }

    .lni-c-hero-headline-divider {
        border-top-width: 0;
        border-right-width: 3px;
        width: 0;
        margin: 0 $space * 2;
    }
}




.lni-c-hero-grid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 100%;
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
    -ms-grid-columns: 100%;
    grid-gap: math.div($space, 2);
}

.lni-c-hero-grid.--layout-a {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto;
}

.lni-c-hero-grid.--layout-b,
.lni-c-hero-grid.--layout-c,
.lni-c-hero-grid.--layout-d {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
}

.lni-c-hero-grid.--layout-e {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
}

.lni-c-hero-grid.--layout-f,
.lni-c-hero-grid.--layout-g {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
}

.lni-c-hero-grid.--layout-h {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
}

.lni-c-hero-grid.--layout-i {
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
}

@for $i from 1 through 8 {
    .lni-c-hero-block:nth-child(#{$i}) {
        -ms-grid-row: #{2 * $i - 1};
        -ms-grid-column: 1;
    }
}

@include min-screen-width(581px) {
    .lni-c-hero-grid {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: 1fr math.div($space, 2) 1fr;
        -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;

        //Set span amount
        &.--layout-a,
        &.--layout-b,
        &.--layout-c,
        &.--layout-d,
        &.--layout-e,
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.--layout-b {
            .lni-c-hero-block:nth-child(2) {
                grid-column-start: auto;
                grid-column-end: auto;
            }
        }
        &.--layout-c,
        &.--layout-d {
            .lni-c-hero-block:nth-child(2) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.--layout-f {
            .lni-c-hero-block:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.--layout-a,
        &.--layout-b,
        &.--layout-d,
        &.--layout-g {
            .lni-c-hero-block:last-child {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.--layout-a,
        &.--layout-d {
            .lni-c-hero-block:nth-last-child(2) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.--layout-f {
            .lni-c-hero-block:nth-child(4) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
        }
        &.lni-c-hero-grid.--layout-f {
            .lni-c-hero-block:nth-child(5) {
                grid-column-start: auto;
                grid-column-end: auto;
            }
        }
        &.--layout-f,
        &.--layout-i {
            .lni-c-hero-block:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }
        }
        &.--layout-f,
        &.--layout-i {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-row: 3;
            }
        }
        &.--layout-b,
        &.--layout-e,
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }
        }
        &.--layout-i {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-row: 3;
            }
        }
        &.--layout-b,
        &.--layout-c,
        &.--layout-e,
        &.--layout-f,
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-row: 5;
            }
        }
        &.--layout-c,
        &.--layout-i {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-column: 3;
            }
        }
        &.--layout-e,
        &.--layout-g,
        &.--layout-h,
        &.--layout-i {
            .lni-c-hero-block:nth-child(5) {
                -ms-grid-row: 5;
            }
        }
        &.--layout-f {
            .lni-c-hero-block:nth-child(5) {
                -ms-grid-row: 7;
            }
        }
        &.--layout-e,
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(5) {
                -ms-grid-column: 3;
            }
        }
        &.--layout-f,
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(6) {
                -ms-grid-row: 7;
            }
        }
        &.--layout-i {
            .lni-c-hero-block:nth-child(6) {
                -ms-grid-row: 5;
            }
        }
        &.--layout-f,
        &.--layout-i {
            .lni-c-hero-block:nth-child(6) {
                -ms-grid-column: 3;
            }
        }
        &.--layout-h,
        &.--layout-i {
            .lni-c-hero-block:nth-child(7) {
                -ms-grid-row: 7;
            }
        }
        &.--layout-h {
            .lni-c-hero-block:nth-child(7) {
                -ms-grid-column: 3;
            }
        }
        &.--layout-i {
            .lni-c-hero-block:nth-child(8) {
                -ms-grid-column: 3;
                -ms-grid-row: 7;
            }
        }
    }

    .--layout-home {
        .lni-c-hero-block:nth-child(1),
        .lni-c-hero-block:nth-child(2),
        .lni-c-hero-block:nth-child(5) {
            grid-column-start: 1;
            grid-column-end: 3;
            -ms-grid-column-span: 3;
        }
        .lni-c-hero-block:nth-child(4) {
            -ms-grid-row: 5;
            -ms-grid-column: 3;
        }
        .lni-c-hero-block:nth-child(5) {
            -ms-grid-row: 7;
            -ms-grid-column: 1;
        }
    }
}

@include min-screen-width(715px) {
    .lni-c-hero-grid {
        &.--layout-home {
            grid-template-columns: repeat(2, 1fr);
            -ms-grid-columns: 1fr math.div($space, 2) 1fr;
            .lni-c-hero-block:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 3;
            }
            .lni-c-hero-block:nth-child(2) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
                grid-row-start: 2;
                grid-row-end: 5;
                -ms-grid-row-span: 5;
                grid-column-start: auto;
                grid-column-end: auto;
            }

            @for $x from 1 through 3 {
                .lni-c-hero-block:nth-child(#{$x + 2}) {
                    -ms-grid-row: #{($x * 2 - 1) + 2};
                    -ms-grid-column: 3;
                    grid-column-start: auto;
                    grid-column-end: auto;
                }
            }
        }
    }
}

@include min-screen-width(960px) {
    .lni-c-hero-grid {
        &.--layout-home {
            grid-template-columns: repeat(3, 1fr);
            -ms-grid-columns: 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr;

            .lni-c-hero-block:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 5;
                -ms-grid-row-span: 1;
                grid-column-start: 3;
                grid-row-start: 1;
                grid-row-end: 1;
            }

            @for $x from 1 through 3 {
                .lni-c-hero-block:nth-child(#{$x + 2}) {
                    -ms-grid-row: 3;
                    -ms-grid-column: #{2 * $x - 1};
                    grid-row-start: 2;
                    grid-column-start: auto;
                    grid-column-end: auto;
                }
            }
        }
    }
}

@include min-screen-width(1142px) {
    .lni-c-hero-grid {
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr;
        -ms-grid-rows: auto math.div($space, 2) auto;
        &.--layout-a,
        &.--layout-b,
        &.--layout-c,
        &.--layout-e,
        &.--layout-h {
            .lni-c-hero-block:nth-child(1) {
                grid-row-start: 1;
                grid-row-end: 3;
                -ms-grid-row-span: 3;
            }
        }
        &.--layout-a,
        &.--layout-c,
        &.--layout-d {
            .lni-c-hero-block:nth-child(2) {
                grid-column-start: 3;
                grid-column-end: 5;
            }
        }
        &.--layout-a,
        &.--layout-f {
            .lni-c-hero-block:nth-child(3) {
                grid-column-start: 3;
                grid-column-end: 5;
            }
        }
        &.--layout-b,
        &.--layout-d {
            .lni-c-hero-block:nth-child(4) {
                grid-column-start: 3;
                grid-column-end: 5;
            }
        }
        &.--layout-g {
            .lni-c-hero-block:nth-child(6) {
                grid-column-start: 3;
                grid-column-end: 5;
            }
        }
        &.--layout-h {
            .lni-c-hero-block:nth-child(1) {
                grid-column-start: auto;
                grid-column-end: auto;
                -ms-grid-column-span: 1;
            }
        }
        &.--layout-a,
        &.--layout-b,
        &.--layout-c,
        &.--layout-d,
        &.--layout-e,
        &.--layout-g {
            .lni-c-hero-block:nth-child(2) {
                -ms-grid-column: 5;
                -ms-grid-row: 1;
            }
        }
        &.--layout-f,
        &.--layout-h,
        &.--layout-i {
            .lni-c-hero-block:nth-child(2) {
                -ms-grid-column: 3;
                -ms-grid-row: 1;
            }
        }
        &.--layout-c {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-column: 5;
                -ms-grid-row: 3;
            }
        }
        &.--layout-b,
        &.--layout-e,
        &.--layout-g {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-column: 7;
                -ms-grid-row: 1;
            }
        }
        &.--layout-f,
        &.--layout-h,
        &.--layout-i {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-column: 5;
                -ms-grid-row: 1;
            }
        }
        &.--layout-d {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-row: 3;
            }
        }
        &.--layout-a {
            .lni-c-hero-block:nth-child(3) {
                -ms-grid-column: 5;
                -ms-grid-row: 3;
            }
        }
        &.--layout-b,
        &.--layout-d,
        &.--layout-e {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-column: 5;
                -ms-grid-row: 3;
            }
        }
        &.--layout-c {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-column: 7;
                -ms-grid-row: 3;
            }
        }
        &.--layout-f,
        &.--layout-g {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-column: 1;
                -ms-grid-row: 3;
            }
        }
        &.--layout-h,
        &.--layout-i, {
            .lni-c-hero-block:nth-child(4) {
                -ms-grid-column: 7;
                -ms-grid-row: 1;
            }
        }
        &.--layout-f,
        &.--layout-g,
        &.--layout-h,
        &.--layout-i {
            .lni-c-hero-block:nth-child(5),
            .lni-c-hero-block:nth-child(6),
            .lni-c-hero-block:nth-child(7),
            .lni-c-hero-block:nth-child(8), {
                -ms-grid-row: 3;
            }
        }
        &.--layout-e {
            .lni-c-hero-block:nth-child(5) {
                -ms-grid-column: 7;
                -ms-grid-row: 3;
            }
        }
        &.--layout-f {
            .lni-c-hero-block:nth-child(5) {
                -ms-grid-column: 5;
            }
        }
        &.--layout-g,
        &.--layout-h {
            .lni-c-hero-block:nth-child(6) {
                -ms-grid-column: 5;
            }
        }
        &.--layout-f {
            .lni-c-hero-block:nth-child(6) {
                -ms-grid-column: 7;
            }
        }
        &.--layout-h {
            .lni-c-hero-block:nth-child(7) {
                -ms-grid-column: 7;
            }
        }
        &.--layout-i {
            .lni-c-hero-block:nth-child(7) {
                -ms-grid-column: 5;
            }
            .lni-c-hero-block:nth-child(8) {
                -ms-grid-column: 7;
            }
        }
    }
}
/* stylelint-enable no-duplicate-selectors */