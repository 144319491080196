/*
    ╔═══════════════════════════════════════════════════════════════════════════════════════════╗
    ║    COLORS                                                                                 ║
    ║                                                                                           ║
    ║    L&I Color Palette - http:/*www.color-blindness.com/color-name-hue/                     ║
    ║                                                                                           ║
    ║    The palette contains all available color options.                                      ║
    ║    DO NOT USE COLORS DIRECTLY FROM THE PALETTE - instead, use or create a variable        ║
    ║    with a functional name                                                                 ║
    ╚═══════════════════════════════════════════════════════════════════════════════════════════╝
*/

$palette: (
    "black": hsl(0deg 0% 0%),
    "white": hsl(0deg 0% 100%),
    "porcelain": hsl(210deg 6% 94%),
    "alto": hsl(0deg 0% 86%),
    "translucent-gray": hsl(234deg 9% 40% / 50%), /*does not pass for any text */
    "dark-gray": hsl(234deg 9% 20%),
    "mid-gray": hsl(234deg 9% 30%),
    "gray": hsl(234deg 9% 49%), /* minimum contrast against white */
    "light-gray": hsl(234deg 9% 60%), /* only passes for 18px or 14px in bold */
    "lightest-gray": hsl(234deg 6% 70%),
    "cornflower-blue": hsl(219deg 32% 81%),
    "deep-sapphire": hsl(222deg 91% 21%), /* breadcrumb-title bar, secondary text, icons */
    "catalina-blue": hsl(216deg 87% 25%), /* used as the dark part of top gradient */
    "bahama-blue": hsl(206deg 100% 29%), /* used as the logo mark color; light part of top gradient */
    "legacy-dark-green": hsl(188deg 27% 21%),
    "legacy-light-green": hsl(183deg 27% 31%),
    "bahama-blue-medium": hsl(206deg 98% 38%), /* links */
    "translucent-blue": hsl(216deg 100% 39% / 50%),
    "science-blue": hsl(216deg 100% 39%),
    "monza": hsl(349deg 100% 35%),
    "gamboge": hsl(39deg 91% 48%),
    "japanese-laurel": hsl(107deg 100% 29%),
);

/**
 * THEMES
 *
 * Some colors have themes that, when used together in things like badges and alert or warning
 * messages, have a more coordinated, professional appearance.
 *
 * IMPORTANT: These themes were designed with accessible contrast ratios in mind. Do not use these
 * colors individually outside of their theme or mix them with other themes. Use a single theme
 * altogether.
 */

$themes: (
    "monza-pale": hsl(349deg 68% 95%),
    "monza-light": hsl(349deg 92% 67%),
    "monza-medium": map-get($palette, monza),
    "monza-dark": hsl(349deg 95% 24%),
    "japanese-laurel-pale": hsl(107deg 39% 93%),
    "japanese-laurel-light": map-get($palette, japanese-laurel),
    "japanese-laurel-medium": hsl(107deg 98% 22%),
    "japanese-laurel-dark": hsl(107deg 91% 17%),
    "gamboge-pale": hsl(39deg 96% 92%),
    "gamboge-light": map-get($palette, gamboge),
    "gamboge-light-alt": hsl(39deg 90% 41%),
    "gamboge-medium": hsl(39deg 87% 35%),
    "gamboge-dark": hsl(39deg 61% 25%),
    "bahama-blue-pale": hsl(206deg 49% 90%),
    "bahama-blue-light": hsl(206deg 90% 54%),
    "bahama-blue-medium": hsl(206deg 98% 38%),
    "bahama-blue-dark": map-get($palette, bahama-blue),
);

/* Color Stacks */
$color-stacks: (
    "green-10": hsl(107deg 39% 93%), /*japanese-laurel-pale */
    "green-70": hsl(107deg 100% 29%), /* japanese-laurel and japanese-laurel-light */
    "green-80": hsl(107deg 98% 22%), /* japanese-laurel-medium */
    "green-90": hsl(107deg 91% 17%), /* japanese-laurel-dark */
    "red-10": hsl(349deg 68% 95%), /* monza-pale */
    "red-30": hsl(349deg 92% 67%), /* monza-light */
    "red-70": hsl(349deg 100% 35%), /* monza and monza-medium */
    "red-80": hsl(349deg 95% 24%),  /* monza-dark */
    "yellow-10": hsl(39deg 96% 92%), /* gamboge-pale */
    "yellow-50": hsl(39deg 91% 48%), /* gamboge and gamboge-light */
    "yellow-60": hsl(39deg 90% 41%), /* gamboge-light-alt */
    "yellow-70": hsl(39deg 87% 35%), /* gamboge-medium */
    "yellow-80": hsl(39deg 61% 25%), /* gamboge-dark */
    "blue-10": hsl(206deg 49% 90%), /* bahama-blue-pale */
    "blue-20": hsl(219deg 32% 81%), /* cornflower */
    "blue-50": hsl(206deg 90% 54%), /* bahama-blue-light */
    "blue-55": hsl(206deg 98% 38%), /* bahama-blue-medium (replaced lochmara) */
    "blue-60": hsl(216deg 100% 39%), /* science-blue */
    "blue-70": hsl(206deg 100% 29%), /* bahama blue and bahama-blue-dark */
    "blue-75": hsl(216deg 87% 25%), /* catalina-blue */
    "blue-80": hsl(222deg 91% 21%), /* deep-sapphire */
    "neutral-00": hsl(0deg 0% 100%), /* white */
    "neutral-05": hsl(210deg 6% 94%), /* porcelain */
    "neutral-15": hsl(0deg 0% 86%), /* alto */
    "neutral-30": hsl(234deg 6% 70%), /* lightest-gray (replaced translucent-gray) */
    "neutral-40": hsl(234deg 9% 60%), /* light-gray */
    "neutral-50": hsl(234deg 9% 49%), /* gray */
    "neutral-70": hsl(234deg 9% 30%), /* mid-gray */
    "neutral-80": hsl(234deg 9% 20%), /* dark-gray */
    "neutral-100": hsl(0deg 0% 0%), /* black */
    "blue-overlay": hsl(216deg 100% 39% / 50%), /* translucent-blue */
    "neutral-overlay--dark": hsla(234deg 9% 40% / 50%), /* translucent-gray */
    "neutral-overlay--light": hsla(0deg 0% 100% / 85%), /* white */
);


/* Alert - red-hued, for errors and serious messages */
$error--text: map-get($themes, monza-dark);
$error--icon: map-get($themes, monza-medium);
$error--border: map-get($themes, monza-light);
$error--background: map-get($themes, monza-pale);

/* Warning - orange-hued, for caution */
$warn--text: map-get($themes, gamboge-dark);
$warn--icon: map-get($themes, gamboge-medium);
$warn--border: map-get($themes, gamboge-light-alt);
$warn--background: map-get($themes, gamboge-pale);

/* Positive - green-hued, signals all is well or active */
$success--text: map-get($themes, japanese-laurel-dark);
$success--icon: map-get($themes, japanese-laurel-medium);
$success--border: map-get($themes, japanese-laurel-light);
$success--background: map-get($themes, japanese-laurel-pale);

/* Info - blue-hued, for confirmation messages or neutral statuses */
$info--text: map-get($themes, bahama-blue-dark);
$info--icon: map-get($themes, bahama-blue-medium);
$info--border: map-get($themes, bahama-blue-light);
$info--background: map-get($themes, bahama-blue-pale);

/**
 * STANDALONE COLORS
 *
 * Use these colors at will, but only according to their assigned role.
 */

/* Text */
$text--primary: map-get($palette, mid-gray);
$text--action: map-get($palette, science-blue);
$text--action-hover: map-get($palette, bahama-blue-medium);
$text--clickable: map-get($palette, bahama-blue-medium);
$text--callout: map-get($palette, catalina-blue);
$text--hover: map-get($palette, catalina-blue);
$text--error: map-get($palette, monza);
$text--inverse: map-get($palette, "white");
$text--alternate: map-get($palette, catalina-blue); /* TODO: needs functional name */
$text--alternate-hover: map-get($palette, bahama-blue-medium); /* TODO: needs functional name */
$text--lighter: map-get($palette, "gray"); /* TODO: needs functional name */
$text--lightest: map-get($palette, light-gray); /* TODO: needs functional name */
$text--darker: map-get($palette, dark-gray); /* TODO: needs functional name */
$text--success: map-get($palette, japanese-laurel);

/* Focus */
$focus-ring: map-get($palette, catalina-blue);
$focus-ring--inverse: map-get($palette, "white");

/* Borders */
$border--base: map-get($palette, mid-gray);
$border--callout: map-get($palette, catalina-blue);
$border--bright: map-get($palette, science-blue); /* TODO: needs functional name */
$border--error: map-get($palette, monza);
$border--dark: map-get($palette, dark-gray);
$border--light: map-get($palette, porcelain);
$border--white: map-get($palette, "white"); /* TODO: needs functional name */
$border--subtle: map-get($palette, lightest-gray);
$border--success: map-get($palette, japanese-laurel);

/* Backgrounds */
$background--content: map-get($palette, "white");
$background--action: map-get($palette, science-blue);
$background--action-hover: map-get($palette, bahama-blue-medium);
$background--alternate: map-get($palette, porcelain); /* TODO: needs functional name */
$background--site: map-get($palette, porcelain);
$background--callout: map-get($palette, porcelain);
$background--display: map-get($palette, porcelain);
$background--dark: map-get($palette, deep-sapphire); /* TODO: needs functional name */
$background--site-header: linear-gradient(166.25deg, map-get($palette, bahama-blue), map-get($palette, deep-sapphire));
$background--secure-site-header: linear-gradient(79deg, map-get($palette, legacy-dark-green), map-get($palette, legacy-light-green));
$background--highlight: map-get($palette, bahama-blue);
$background--highlight--hover: map-get($themes, bahama-blue-medium);
$background--neutral: map-get($palette, mid-gray); /* TODO: needs functional name */
$background--light-neutral: map-get($palette, "gray"); /* TODO: needs functional name */
$background--subtle: map-get($palette, lightest-gray);
$background--error: map-get($palette, monza);
$background--warn: map-get($palette, gamboge);
$background--okay: map-get($palette, japanese-laurel);
$background--info-pale: map-get($themes, bahama-blue-pale); /* TODO: needs functional name */
$background--info: map-get($palette, bahama-blue);
$background--overlay: map-get($palette, translucent-gray); /* only used for overlays see (lni-modal, lni-spinner) */
$background--overlay--light: map-get($color-stacks, neutral-overlay--light);
$background-color--deep: map-get($palette, porcelain); /* TODO: needs better name */

/* Shadows */
$text-shadow--dark: map-get($palette, "black");

/*  Code  */
$code__background: map-get($palette, mid-gray);
$code__text: map-get($palette, "white");

/* Inputs */
$input--active: map-get($palette, science-blue);
$input--inactive: map-get($palette, mid-gray);
$input--base: map-get($palette, dark-gray);
$input--error: map-get($palette, monza);
$input--filled: map-get($palette, porcelain);
$input__label--inactive: $input--inactive;
$input__label--activated: $input--base;
$input__label--focused: $input--active;
$input__label--error: $input--error;
$input__background--filled: $input--filled;
$input__background--selected: $input--active; /* for checkboxes */

$input-suggestion__background: map-get($palette, porcelain);
$input__border--base: $input--base;
$input__border--selected: $input--active;
$input__border--error: $input--error;
$input__border--inactive: $input--inactive;
$input-suggestion__border: map-get($palette, "black");
$input__shadow--active: map-get($palette, translucent-blue);
$input__shadow--focused: map-get($palette, translucent-blue);
$select__text--disabled: map-get($palette, alto);
$textarea-counter--warn: map-get($palette, gamboge);
$textarea-counter--error: $input--error;

/* The pupil for radio buttons */
$input__mark--base: $input--active;

/* Radios & Checkboxes */
$chip__background: transparent;
$chip__background--hover: map-get($palette, porcelain);
$chip__background--checked: map-get($palette, cornflower-blue);
$chip__border: map-get($palette, "gray");
$chip__border--hover: map-get($palette, bahama-blue);
$chip__border--checked: map-get($palette, cornflower-blue);

/* Buttons */
$button__background: $background--action;
$button__border--focus: map-get($palette, bahama-blue-medium);
$button__background--hover: $background--action-hover;
$button__text: map-get($palette, "white");
$button__text--text: $text--action;
$button__text--text--hover: $text--action-hover;
$button__background--alternate: map-get($palette, "white");
$button__text--alternate: map-get($palette, science-blue);
$button__text--alternate--text: map-get($palette, catalina-blue);
$button__text--alternate--text--hover: $text--alternate-hover;
$button__border--alternate: map-get($palette, alto);
$button__background--alternate--hover: $background--alternate;
$button__background--disabled: map-get($palette, alto);
$button__text--disabled: map-get($palette, "gray");

/* Tables */
$table__background: map-get($palette, "white");
$table__background--alternate: map-get($palette, porcelain);
$table__border--base: map-get($palette, alto);
$menu-item__background--mobile: map-get($palette, catalina-blue);
$menu-item__background--left-nav: map-get($palette, porcelain);

/* Site / Templates / Navigation */

/* Header */
$site-header--background: map-get($palette, catalina-blue);
$site-header__border: map-get($palette, "white");
$language-icon--accent: map-get($palette, bahama-blue-medium);
$mega-menu__background: map-get($palette, "white");
$mega-menu__background--alternate: map-get($palette, cornflower-blue);
$mega-menu__background--mobile: map-get($palette, bahama-blue);
$mega-menu-secure__background--mobile: map-get($palette, legacy-light-green);
$mega-menu-secure__dark-background--mobile: map-get($palette, legacy-dark-green);
$mega-menu__background--utility: map-get($palette, bahama-blue);
$mega-menu__tab__background: map-get($palette, "white");
$mega-menu__border--subtle: map-get($palette, bahama-blue);
$mega-menu__border--inverse: map-get($palette, "white");
$mega-menu__border--primary: map-get($palette, deep-sapphire);
$mega-menu__text--primary: map-get($palette, deep-sapphire);
$mega-menu__text--inverse: map-get($palette, "white");
$title-bar__background: map-get($palette, deep-sapphire);

/* Side Nav */
$side-nav-third-level__background--hover: map-get($palette, alto);
$side-nav-fourth-level__background--hover: map-get($palette, deep-sapphire);
$side-nav-fourth-level__background: map-get($palette, "white");
$side-nav__text--primary: map-get($palette, catalina-blue);
$side-nav__text--alternate: map-get($palette, dark-gray);
$side-nav__border--item: map-get($palette, alto);

/* Footer */
$site-legal-footer__border: $border--white;
$site-legal-footer__background: $background--neutral;
$site-functional-footer__background: map-get($palette, catalina-blue);
$site-functional-footer__icon: map-get($palette, catalina-blue);
$site-functional-footer__icon--hover: map-get($palette, 'white');
$content__background: map-get($palette, porcelain);

/* Components */
$questions__background--active: map-get($palette, porcelain);
$callout__text--headline: map-get($palette, dark-gray);
$inline-tabs__background: transparent; /* TODO: is this a color */
$inline-tabs__background--active: map-get($palette, cornflower-blue);
$inline-tabs__background--hover: map-get($palette, porcelain);
$inline-tabs__border: map-get($palette, light-gray);
$inline-tabs__border--active: map-get($palette, science-blue);
$inline-tabs__border--hover: map-get($palette, cornflower-blue);
$inline-tabs__text: map-get($palette, deep-sapphire);
$inline-tabs__text--active: map-get($palette, deep-sapphire);
$inline-tabs__text--hover: map-get($palette, science-blue);
$language-alert-icon__background: map-get($palette, gamboge);
$language-alert-icon__color: map-get($palette, "white");
$language-alert__border: map-get($palette, gamboge);
$language-alert__text--title: map-get($palette, gamboge);
$site-search__border: map-get($palette, catalina-blue);
$site-search-close__color: map-get($palette, "gray");
$site-search-close__background: map-get($palette, "gray");
$site-search-close__background--hover: map-get($palette, deep-sapphire);
$search-result__background: map-get($palette, porcelain);
$search-result__text: map-get($palette, mid-gray);
$search-result__text--title: map-get($palette, science-blue);
$pager__background--hover: map-get($palette, porcelain);
$pager__border--active: map-get($palette, deep-sapphire);
$pager__color: map-get($palette, science-blue);
$pager__color--active: map-get($palette, deep-sapphire);
$pager-toggle__text: map-get($palette, light-gray);
$link-list__background: map-get($palette, "white");
$link-list-header__background: map-get($palette, science-blue);
$link-list-header__background--hover: map-get($palette, cornflower-blue);
$link-list-header__text: map-get($palette, "white");
$link-list-header-link__text: map-get($palette, "white");
$link-list-header-link__text--hover: map-get($palette, science-blue);
$link-list-link__text: map-get($palette, deep-sapphire);
$link-list-link__border: map-get($palette, alto);
$link-list-link__background--hover: map-get($palette, cornflower-blue);
$link-list-link__icon: map-get($palette, "black");
$link-list-shadow: map-get($palette, "black");
$link-list-link__arrow-icon: map-get($palette, mid-gray);
$login-icon__color: map-get($palette, catalina-blue);
$tile__background: $background--highlight;
$tile__background--hover: $background--highlight--hover;
$tile__text: $text--inverse;
$tout__background: map-get($palette, "white");
$tout__link: map-get($palette, catalina-blue);
$touts__link: map-get($palette, science-blue);
$carousel__toggle: map-get($palette, catalina-blue);
$carousel__toggle--border: map-get($palette, mid-gray);
$carousel__text: map-get($palette, catalina-blue);
$carousel__icon: map-get($palette, "black");
$calculator-display__underline: map-get($palette, mid-gray);
$calculator-display__underline2: map-get($palette, bahama-blue-medium);
$featured__text: map-get($palette, mid-gray);
$featured__background--hover: map-get($palette, cornflower-blue);
$featured__border: map-get($palette, light-gray);
$featured-title__text: map-get($palette, catalina-blue);
$featured-description__text: map-get($palette, dark-gray);
$featured-icon__color: map-get($palette, bahama-blue-medium);
$featured-form-title__text: map-get($palette, bahama-blue-medium);
$rule-making__link--normalize: map-get($palette, mid-gray);
$rule-making__border: map-get($palette, alto);
$rule-making-card__category--text: map-get($palette, mid-gray);
$rule-making-card__comments--text: map-get($palette, mid-gray);
$rule-making-card__column--border: map-get($palette, alto);
$rule-making-activity__row--background: map-get($palette, science-blue);
$rule-making-activity__icon: map-get($palette, mid-gray);
$rule-making-activity__title--text: map-get($palette, mid-gray);
$rule-making-activity__icon--active: map-get($palette, science-blue);
$rule-making-activity__link: map-get($palette, science-blue);
$order-confirm-notification__background: map-get($palette, cornflower-blue);
$order-confirm-notification__background--fail: map-get($palette, monza);
$order-confirm-notification__text--fail: map-get($palette, "white");
$order-confirm-header-border__background: map-get($palette, bahama-blue-medium);
$order-confirm-border__background: map-get($palette, alto);
$sharp-card-link__text: map-get($palette, science-blue);
$self-insured-employers-card-header__text: map-get($palette, deep-sapphire);
$self-insured-employers-card-title__text: map-get($palette, "gray");
$ship-grant-projects-card__border: map-get($palette, alto);
$blog-background: map-get($palette, "white");

/*
    ╔═══════════════════════════════════════════╗
    ║   SPACING AND TYPOGRAPHY                  ║
    ╚═══════════════════════════════════════════╝
*/

$font-size--base: 18px;
$line-height--base: 1.6667;
$line-height--tight: 1.3333;
$space: 1rem * $line-height--base; /* .approx 30px; */

$reading-line-width: 40em;
$site-content-width: 56.6667rem;
$side-nav-width: 13.6667rem;
$side-nav-width--min: 11.6667rem;
$side-nav-content-width: 43rem;
$mobile-navigation-breakpoint: 38.6667rem;
$default-form-width: 28rem;
$mobile-search-breakpoint: 450px;
$font-family--legacy: "Lucida Sans Unicode",
    "Lucida Grande",
    "Segoe UI",
    "Helvetica Neue",
    sans-serif;
$font-family--system: system-ui,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
$font-family--mono: SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New";
$font-family--roboto: "Roboto", $font-family--system;
$font-family--franklin: "Libre Franklin", $font-family--roboto;
$radio-button-size: 20px; /* using pixels to prevent subpixel rounding */
$checkbox-size: 20px;
$input-width: 18rem;

/*
    ╔═══════════════════════════════════════════╗
    ║   ANIMATIONS AND TRANSITIONS              ║
    ╚═══════════════════════════════════════════╝
*/

$gentle-ease: cubic-bezier(0.25, 0.8, 0.25, 1);


/*
    ╔═══════════════════════════════════════════╗
    ║   ELEMENT FORMATING                       ║
    ╚═══════════════════════════════════════════╝
*/

$border-radii--base: 0.5rem;
$border-radii--small: 4px;
$modal__border-radii: $border-radii--base;
$button__border-radii: 4px;
$button__border-radii--large: 5px;
$top-highlight: inset 0 3px 3px -1px #{transparentize(map-get($palette, "white"), 0.5)};
$mega-menu-width: 60rem;
$megaMenuBreakPoint: 715px; /* TODO: convert to ems */
$responsiveSmallDeviceWidth: 570px;