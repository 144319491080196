@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-touts {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
    -ms-grid-columns: 100%;
    grid-gap: math.div($space, 2);
}

@for $i from 1 through 4 {
    .lni-c-tout-child:nth-child(#{$i}) {
        -ms-grid-row: #{2 * $i - 1};
        -ms-grid-column: 1;
    }
}

.lni-c-touts__link {
    text-decoration: none;
    color: $touts__link;
    font-family: $font-family--franklin;
}

.lni-c-tout-child {
    background: $tout__background;
    border-radius: $border-radii--small;
}

.lni-c-tout__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.lni-c-tout__link {
    @include type-setting(sm);

    color: $tout__link;
    text-decoration: none;
    font-family: $font-family--franklin;
}

.lni-c-tout__title {
    @include type-setting(xxs);

    letter-spacing: 2px;
}


@include min-screen-width(581px) {
    .lni-c-touts {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: 1fr math.div($space, 2) 1fr;
        -ms-grid-rows: auto math.div($space, 2) auto;
    }

    .lni-c-tout-child:nth-child(3):last-child {
        grid-column-start: 1;
        grid-column-end: 3;
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
    }

    .lni-c-tout-child:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }

    .lni-c-tout-child:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }


    .lni-c-tout-child:last-child {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
    }
}

@include min-screen-width(1142px) {
    .lni-c-touts {
        grid-template-columns: repeat(3, 1fr);
        -ms-grid-columns: 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr;
        -ms-grid-rows: 1fr;
        &.--columns-4 {
            grid-template-columns: repeat(4, 1fr);
            -ms-grid-columns: 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr math.div($space, 2) 1fr;
            -ms-grid-rows: 1fr;
        }
    }
    .lni-c-tout-child:nth-child(3) {
        -ms-grid-row: 1;
        -ms-grid-column: 5;
        -ms-grid-column-span: 1;
    }
    .lni-c-tout-child,
    .lni-c-tout-child:nth-child(3):last-child {
        grid-column-start: auto;
        grid-column-end: auto;
        -ms-grid-row: 1;
        -ms-grid-column: 5;
        -ms-grid-column-span: 1;
    }
    .lni-c-tout-child:nth-child(4) {
        -ms-grid-row: 1;
        -ms-grid-column: 7;
    }
}