@mixin box-shadow($z-height: 1, $preceding: false, $trailing: false) {
    @if $z-height == -1 {
        @if $preceding {
            box-shadow: #{$preceding}, inset 0 1px 2px rgb(0 0 0 / 12%), inset 0 1px 2px rgb(0 0 0 / 24%);
        }

 @else {
            box-shadow: inset 0 1px 2px rgb(0 0 0 / 12%), inset 0 1px 2px rgb(0 0 0 / 24%);
        }
    }

 @else if $z-height == 1 {
        @if $preceding {
            box-shadow: #{$preceding}, 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        }

 @else {
            box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        }
    }

 @else if $z-height == 2 {
        @if $preceding {
            box-shadow: #{$preceding}, 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
        }

 @else {
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
        }
    }

 @else if $z-height == 3 {
        @if $preceding {
            box-shadow: #{$preceding}, 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
        }

 @else {
            box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
        }
    }

 @else if $z-height == 4 {
        @if $preceding {
            box-shadow: #{$preceding}, 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
        }

 @else {
            box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
        }
    }

 @else if $z-height == 5 {
        @if $preceding {
            box-shadow: #{$preceding}, 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
        }

 @else {
            box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
        }
    }
}
