@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-language-alert {
    border-top: math.div($space, 4) solid $language-alert__border;
}

.lni-language-alert__header {
    color: $language-alert__text--title;
    margin-top: math.div(-$space, 8);
}

.lni-language-alert__icon {
    background-color: $language-alert-icon__background;
    color: $language-alert-icon__color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
}

.lni-language-alert__content.lni-language-alert__content {
    margin-top: math.div($space, 4);
    margin-bottom: math.div($space, 2);
}