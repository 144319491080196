@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-tile {
    background-color: $tile__background;
    border-radius: 6px;
    flex: 1 0 275px;
    color: $tile__text;
    min-height: $space * 3;
    font-weight: 600;
    text-decoration: none;
    font-family: $font-family--franklin;
    transition: 0.2s ease;
    &:hover {
        background-color: $tile__background--hover;
        color: $tile__text;
        & .lni-c-tile-icon {
            opacity: 1;
        }
    }
    &:focus {
        outline-color: $tile__text;
        outline-offset: -4px;
    }
}

.lni-c-tile-icon {
    opacity: 0;
}