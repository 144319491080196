@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-login {
    background-color: $background--content;
    border-radius: 6px;
    flex: 1 1 280px;
}

.lni-c-login-form {
    max-width: 30rem;
}

.lni-c-login-header {
    @include type-setting(lg);

    background: transparent;
    border: none;
    border-radius: $button__border-radii;
    color: $login-icon__color;
    font-family: $font-family--franklin;
    max-width: 30rem;
    &.--mobile {
        cursor: pointer;
    }
    &:focus {
        border: 1px solid $button__border--focus;
    }
}