@use "sass:math";

.landing-page {
    --COLOR-NEUTRAL-70: #{map-get($palette, mid-gray)}; // This line for demo purposes only
    --_subheading-color: var(--subheading-color, var(--COLOR-NEUTRAL-70));
}

.landing-page .lni-c-landing-page__hero-content {
    margin-top: math.div($space, -2); //overlap bottom of image to encourage user to scroll for more
}

.landing-page .lni-c-landing-page__hero {
    min-height: 315px;
    background-position: center top;
    background-color: $background--dark;
    background-size: cover;
    padding: 126px 0 $space 0; // positions content, TODO: allow for art direction to avoid magic numbers
    position: relative;
    background-repeat: no-repeat;
}

// TODO: test if pixel value magic-numbers work for zoom.
@media screen and (width <= 570px) {
    .lni-c-landing-page__hero {
        background-image: var(--heroImgSm);
    }
}

@media screen and (width >= 571px) and (width <= 1119px) {
    .lni-c-landing-page__hero {
        background-image: var(--heroImgMd);
    }
}

@media screen and (width >= 1120px) {
    .lni-c-landing-page__hero-content {
        padding-top: 40px;
        justify-content: stretch;
    }
    .lni-c-landing-page__hero {
        background-image: var(--heroImgLg);
    }
}

.lni-c-landing-page__hero-content {
    max-width: 1030px;
    position: relative;
    background-color: $background--overlay--light;
    padding: 1rem;
    border-radius: 6px;

    @include box-shadow(2);
}

.lni-c-hero-subheadline-title {
    color: $text--inverse;
    text-align: center;
    font: bold 3em $font-family--franklin;
    text-shadow: 2px 2px $text-shadow--dark;
}

.lni-c-hero-subheadline-description {
    color: var(--_subheading-color);
    font-size: 1.45rem;
    line-height: 1.7rem;
    width: 80%;
}



@media screen and (width >= 1120px) {
    .lni-c-landing-page__hero {
        background-size: contain;
    }
}

.lni-c-hero-headline-title,
.lni-c-hero-headline-description {
    text-shadow: 1px 1px 1px $text-shadow--dark, -1px -1px 2px $text-shadow--dark;
}
