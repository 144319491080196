// TODO: remove styles already in landing page css.
.home-page {
    --COLOR-BLUE-80: #{map-get($palette, deep-sapphire)}; // 'This line for demo purposes only
    --_heading-color: var(--heading-color, var(--COLOR-BLUE-80));
    --_hero-image-background-color: var(--image-background, var(--COLOR-BLUE-80));

    .lni-c-hero-title {
        position: relative;
        z-index: 0;
        width: 100%;
        color: $text--inverse;
        text-align: center;
        font: 700 2.125em $font-family--franklin;
        text-shadow: 2px 2px $text-shadow--dark;
        padding: 2rem;
    }
    .lni-c-landing-page__hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 490px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        background-color: var(--_hero-image-background-color);
        padding: 30px 0 50px 0;
    }

    .lni-c-landing-page__hero-content {
        position: relative;
        background-color: $background--overlay--light;
        padding: 1rem;
        border-radius: 6px;

        @include box-shadow(2);
    }

    // Section area heads
    .lni-section-text {
        display: flex;
        justify-content: center;
        font-family: $font-family--franklin;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--_heading-color);
    }

    .lni-c-link-list-home {
        margin-top: -115px;
        position: relative;
    }
    .lni-c-link-list-header {
        background-color: var(--_heading-color);
    }

    // Carousel/MyLNI sign-in
    .lni-c-slide__headline {
        font-size: 1.25rem;
        line-height: 1.6667rem;
    }
    .lni-c-carousel__wrapper,
    .lni-c-login {
        @include box-shadow(1)
    }
    .lni-c-touts-2-col {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.8333rem;
    }

    // Latest news/Twitter feed
    .lni-c-touts-thirds-col {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 0.8333rem;
    }

    .lni-c-news-and-data-center__twitter {
        padding: 0.5rem;
    }

    // Media queries
    @media screen and (width <= 749px) {
        .lni-c-touts-2-col,
        .lni-c-touts-thirds-col,
        .lni-c-hero-grid.\--layout-home {
            grid-template-columns: none;
        }
    }

    @media screen and (width >= 715px) {
        .lni-c-landing-page__hero {
            min-height: 490px;
            align-items: flex-start;
        }
    }

    @media screen and (width <= 959px) {
        .lni-c-hero-title {
            margin-bottom: 6rem;
        }
    }

    @media screen and (width <= 1070px) {
        .lni-u-mh2,
        .lni-c-landing-page__hero-content {
            margin-left: 0.8333rem;
            margin-right: 0.8333rem;
        }
    }

    @media screen and (width >= 750px) and (width <= 960px) {
        .lni-c-hero-grid.\--layout-home {
            grid-template-columns: repeat(2, 1fr);
        }
        .lni-c-landing-page__hero-content a:nth-child(3) {
            grid-column: span 2;
        }
    }

    @media screen and (width >= 1080) {
        .lni-c-hero-grid.\--layout-home {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (width >= 750px) {
        .lni-c-touts-2-col {
            grid-template-columns: repeat(2, 1fr);
        }
        .lni-c-landing-page__hero {
            min-height: 478px;
        }
        .lni-c-hero-title {
            margin-top: 4rem;
        }
    }

    @media screen and (width >= 1130px) {
        .lni-c-landing-page__hero {
            background-size: contain;
        }
        .lni-c-hero-title {
            margin-bottom: 6rem;
        }
    }

    @media screen and (width <= 1142px) {
        .lni-c-link-list {
            margin-top: 4rem;
        }
    }

    // Home page icons
    //TODO: package these assets differently or handle relative urls in webpack
    .for-providers .lni-c-link-list-header::before {
        content: var(--providersIcon, url('https://lni.wa.gov/application/themes/lni/for-providers-icon.svg'));
    }

    .for-business .lni-c-link-list-header::before {
        content: var(--businessIcon, url('https://lni.wa.gov/application/themes/lni/for-business-icon.svg'));
    }

    .for-workers .lni-c-link-list-header::before {
        content: var(--workersIcon, url('https://lni.wa.gov/application/themes/lni/for-workers-icon.svg'));
    }

    .lni-c-link-list-header span:first-of-type {
        margin-top: 1rem;
    }
}