@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.--qa .lni-c-questions__toggle {
    width: 100%;
    font-weight: 700;
    background: $background--content;
    border: none;
    &.--expanded {
        background-color: transparent;
    }
}

.lni-c-questions__group {
    border-top: 2px groove $border--light;
    padding-block: math.div($space, 4);
}

.lni-c-questions__letter {
    font-family: $font-family--franklin;
    width: $space * 3;
}

.lni-c-questions__trigger {
    width: 100%;
}

.lni-c-questions__indicator {
    width: $space * 3;
}

.lni-c-questions__content-wrapper {
    height: auto;
    width: 100%;

    //Expanded slide directive styles
    &.--expanded {
        display: block;
        height: auto;
    }

    //Display answer on print
    @media print {
        display: block;
        height: auto;
    }
}

.lni-c-questions__content {
    width: 100%;
    padding-top: 7px;
    padding-right: math.div($space, 2);
    padding-bottom: $space;
}

//TODO: isolate this style
.--expanded {
    background-color: $questions__background--active;
}