@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-carousel__wrapper {
    background-color: $background--content;
    border-radius: 6px;
    min-height: $space * 12;
}

.lni-c-carousel__pagination {
    bottom: 0;
    left: 0;
    list-style-type: none;
    right: 0;
}

.lni-c-carousel__toggle {
    margin: math.div($space, 2) math.div($space, 4);
    background-color: transparent;
    border: 3px solid $carousel__toggle--border;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 21px;
    width: 21px;
    padding: 0;
    &:focus {
        box-shadow: 0 0 0 1px $background--content, 0 0 0 3px $focus-ring;
    }
}

.lni-c-carousel__toggle.--active {
    background-color: $carousel__toggle;
    border-color: transparent;
    &:focus {
        box-shadow: 0 0 0 1px $background--content, 0 0 0 3px $focus-ring;
    }
}

.lni-c-slides {
    font-family: $font-family--franklin;
}


.lni-c-slide {
    padding: 40px $space;
}

.lni-c-slide__headline {
    @include type-setting(lg);

    color: $carousel__text;
    .lni-c-slide:hover & {
        text-decoration: underline;
    }
}

@include min-screen-width(580px) {
    .lni-c-slide__headline {
        @include type-setting(xxl);
    }
    .lni-c-carousel__toggle {
        margin: math.div($space, 2);
    }
}


.lni-c-slide__icon {
    color: $carousel__icon;
}

.lni-c-slide__link {
    text-decoration: none;
}

.lni-c-slide__more {
    .lni-c-slide:hover & {
        color: $text--clickable;
    }
}

.lni-c-slide__title {
    @include type-setting(lg);

    color: $carousel__text;
}

.lni-c-play-pause {
    cursor: pointer;
    z-index: 2;
    right: 0;
    color: $text--clickable;
}
