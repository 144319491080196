/**
*
* Takes any valid length value OR sm, md, lg, xl
* See: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
*/

@mixin min-screen-width($size) {
    @if $size == sm {
        @media screen and (width >= 600px) {
            @content;
        }
    }

 @else if $size == md {
        @media screen and (width >= 900px) {
            @content;
        }
    }

 @else if $size == lg {
        @media screen and (width >= 1200px) {
            @content;
        }
    }

 @else if $size == xl {
        @media screen and (width >= 1800px) {
            @content;
        }
    }

 @else {
        @media screen and (min-width: #{$size}) {
            @content;
        }
    }
}
