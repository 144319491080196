@use "sass:math";

.lni-c-link-list {
    --COLOR-BLUE-60: #{map-get($palette, science-blue)};
    --COLOR-BLUE-80: #{map-get($palette, deep-sapphire)};
    --_read-more-link-color: var(--read-more-link-color, var(--COLOR-BLUE-60));
    --_link-color: var(--link-color, var(--COLOR-BLUE-80));
    --_heading-link-color: var(--heading-link-color, var(--COLOR-BLUE-80));
    --_heading-link-hover-color: var(--heading-link-hover-color, var(--COLOR-BLUE-80));

    background-color: $link-list__background;
    border-radius: 6px;

    @include box-shadow(2);
}

.lni-c-link-list-container {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
    -ms-grid-columns: 100%;
    grid-gap: math.div($space, 2);
}


@for $i from 1 through 4 {
    .lni-c-link-list:nth-child(#{$i}) {
        -ms-grid-row: #{2 * $i - 1};
        -ms-grid-column: 1;
    }
}

.lni-c-link-list-icon {
    @include type-setting(xl);
}

.lni-c-link-list-header-icon {
    @include type-setting(md);
}

.lni-c-link-list-header::before {
    position: absolute;
    bottom: 2rem;
    width: 80px;
    left: 50%;
    margin-left: -40px;
}

.lni-c-link-list-item {
    line-height: 1.25rem;
    border-bottom: solid 2px $link-list-link__border;
    color: $link-list-link__text;
}

.lni-c-link-list-item:last-child {
    align-self: flex-end;
    margin-top: 1rem;
    justify-content: space-between;
    font-weight: 900;
    border-bottom: none;
}

.lni-c-link-list-item:last-child > a {
    color: var(--_read-more-link-color);
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 125%;
}

.lni-c-link-list-header {
    background-color: $link-list-header__background;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: $link-list-header__text;
    font-family: $font-family--franklin;
    min-height: 71px;
    transition: 0.2s ease;
    &:hover {
        &.--link {
            background-color: $link-list-header__background--hover;
            .lni-c-link-list-header-link {
                color: $link-list-header-link__text--hover;
            }
        }
    }

    &.--link {
        position: relative;
        background-color: $text--inverse;
        color: var(--_link-color);

        a,
        a:hover {
            color: var(--_heading-link-color);
        }
    }
}

.lni-c-link-list-header-link {
    color: $link-list-header-link__text;
    text-decoration: none;
}

.lni-c-link-list-link {
    color: $link-list-link__text;
    text-decoration: none;
    transition: 0.2s ease;
    &:hover {
        background-color: $link-list-link__background--hover;
        border-radius: 6px;
    }
}

.lni-c-link-list-arrow {
    color: $link-list-link__arrow-icon;
}
