@use "sass:math";

.lni-c-resource-list {
    .lni-c-related-resources__bottom & {
        column-count: 2;
        column-gap: math.div($space, 2);

        .lni-c-resource-list__item {
            display: inline-block;
        }
    }
}

.lni-c-resource-list__item {
    line-height: 1.3333;
    display: block;
}

* + .lni-c-resource-list__item {
    margin-top: math.div($space, 3); //visually appropriate - mck
}